import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import QRCode from "react-qr-code"

const QRModal = props => {
  const { index, isOpen, toggle } = props

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>QR</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <QRCode
              value={`${process.env.REACT_APP_CHECK_HOST}/${index}?channel=${process.env.REACT_APP_CHANNEL_UNI}`}
              className="rounded-sm w-full h-full"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Хаах
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

QRModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default QRModal
