import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "reactstrap"
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap"
import { Link } from "react-router-dom"
import profile1 from "assets/images/profile-img.png"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "./WelcomeComp"
import TableContainerWithModal from "components/Common/TableContainerWithModal"
import {
  DateCell,
  TextCell,
  TextToUpperCase,
} from "pages/CreditLog/creditLogCol"
import { IdCell } from "pages/AllDiplomaList/diplomaListCol"
import { StatusCell } from "pages/OrganizationList/organizationLogCol"
import {
  listOrgDiplomas,
  readDiplomaReport,
  readMyOrgCredit,
} from "helpers/api_helper"
import TopCities from "./TopCities"

const Dashboard = props => {
  //meta title
  document.title = "Хянах самбар | Diplom.mn"

  const [apiData, setApiData] = useState(null)
  const [diplomaReportData, setDiplomaReportData] = useState(null)
  const [orgData, setOrgData] = useState(null)
  const fetchOrgDiplomas = async () => {
    try {
      const r = await listOrgDiplomas()
      const err = r
      if (err?.message != "error") {
        setApiData(r.records)
      }
    } catch (error) {
      return "error"
    }
  }
  const fetchDiplomaReport = async () => {
    try {
      const r = await readDiplomaReport()
      const err = r
      if (err?.message != "error") {
        setDiplomaReportData(r)
      }
    } catch (error) {
      return "error"
    }
  }
  const fetchMyOrg = async () => {
    try {
      const r = await readMyOrgCredit()
      const err = r
      if (err?.message != "error") {
        setOrgData(r)
      }
    } catch (error) {
      return "error"
    }
  }
  useEffect(() => {
    fetchMyOrg()
    fetchDiplomaReport()
    fetchOrgDiplomas()
  }, [])
  const [modal1, setModal1] = useState(false)

  const toggleViewModal = () => setModal1(!modal1)
  const columns = useMemo(
    () => [
      {
        Header: "Өдөр",
        accessor: "createTxTimestamp",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <IdCell {...cellProps} />
        },
      },
      {
        Header: "Дипломын дугаар",
        accessor: "claims.diplomaNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextToUpperCase {...cellProps} />
        },
      },
      {
        Header: "Нэр",
        accessor: "claims.extras.hemisDiploma.FIRST_NAME",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Статус",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusCell {...cellProps} />
        },
      },
      // {
      //   Header: "Дэлгэрэнгүй",
      //   disableFilters: true,
      //   accessor: "view",
      //   Cell: cellProps => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={() => toggleViewModal()}
      //       >
      //         Дэлгэрэнгүй харах
      //       </Button>
      //     )
      //   },
      // },
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("НҮҮР")}
            breadcrumbItem={props.t("Хянах самбар")}
          />
          <Row>
            <Col xl="4">
              {/* <WelcomeComp /> */}
              {diplomaReportData ? (
                <TopCities data={diplomaReportData} />
              ) : null}
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Нийт оруулсан</p>
                          <h4 className="mb-0">
                            {diplomaReportData != null ? (
                              diplomaReportData.totalCount
                            ) : (
                              <></>
                            )}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-archive-in font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Нийт хүлээгдэж буй
                          </p>
                          <h4 className="mb-0">
                            0
                            {/* {diplomaReportData && diplomaReportData.statusCount
                              ? diplomaReportData.totalCount -
                                diplomaReportData?.statusCount[0]
                              : null} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt  font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Нийт кредит</p>
                          <h4 className="mb-0">
                            {orgData != null ? orgData.amount : null}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={"bx bx-purchase-tag-alt font-size-24"}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <div className="mb-4 h4 card-title">
                    Хамгийн сүүлд оруулсан
                  </div>
                  <TableContainerWithModal
                    columns={columns}
                    data={apiData ? apiData : []}
                    isGlobalFilter={true}
                    isShowingPageLength={false}
                    isPagination={true}
                    iscustomPageSizeOptions={true}
                    customPageSize={10}
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    isUpload={false}
                    modalState={modal1}
                    toggleViewModal={toggleViewModal}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
