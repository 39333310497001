import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const UploadDiplomaDetailModal = props => {
  const { data, index, isOpen, toggle, isShowAll } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      isShowAll={isShowAll}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Дипломын дэлгэрэнгүй</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table table-nowrap">
              <tbody>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Нэр:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].FIRST_NAME
                      : data[index].row.FIRST_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Овог:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].LAST_NAME
                      : data[index].row.LAST_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Голч дүн:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].TOTAL_GPA
                      : data[index].row.TOTAL_GPA}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Дипломын дугаар:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].DEGREE_NUMBER
                      : data[index].row.DEGREE_NUMBER}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Регистрийн дугаар:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].PRIMARY_IDENTIFIER_NUMBER
                      : data[index].row.PRIMARY_IDENTIFIER_NUMBER}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Байгууллагын дугаар:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].INSTITUTION_ID
                      : data[index].row.INSTITUTION_ID}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Байгууллага:</h6>
                  </td>
                  <td>
                    {" "}
                    {isShowAll
                      ? data[index].INSTITUTION_NAME
                      : data[index].row.INSTITUTION_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын түвшин:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].EDUCATION_LEVEL_NAME
                      : data[index].row.EDUCATION_LEVEL_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын салбарын код:</h6>
                  </td>
                  <td>
                    {" "}
                    {isShowAll
                      ? data[index].EDUCATION_FIELD_CODE
                      : data[index].row.EDUCATION_FIELD_CODE}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын салбар:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].EDUCATION_FIELD_NAME
                      : data[index].row.EDUCATION_FIELD_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Хичээлийн жил:</h6>
                  </td>
                  <td>
                    {isShowAll
                      ? data[index].CONFER_YEAR_NAME
                      : data[index].row.CONFER_YEAR_NAME}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Хаах
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

UploadDiplomaDetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default UploadDiplomaDetailModal
