import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopCities = ({ data }) => {
  const rejected = data.total - data.valid - data.new
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Олгогдсон гэрчилгээнүүд</CardTitle>
          <div className="text-center">
            <i className="dripicons-graduation text-primary display-4" />
            <h3>{data != null ? data.totalCount : null}</h3>
            <p>Нийт</p>
          </div>

          {data.statusCount >= 1 ? (
            <div className="table-responsive mt-4">
              <table className="table align-middle table-nowrap">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">Баталгаажуулагдсан</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      {data.statusCount >= 1 ? (
                        <h5 className="mb-0">{data.statusCount[1].count}</h5>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <Progress
                        value={(100 * data.valid) / data.total}
                        color={"success"}
                        className="bg-transparent progress-sm"
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">Хүлээгдэж буй</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      {data.statusCount >= 1 ? (
                        <h5 className="mb-0">{data.statusCount[0].count}</h5>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <Progress
                        value={(100 * data.new) / data.total}
                        color={"primary"}
                        className="bg-transparent progress-sm"
                        size="sm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
