import React, { useState } from "react"
import Cookies from "js-cookie"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import toast from "react-hot-toast" // Import toast and Toaster

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { changePassword } from "helpers/api_helper"
import * as Yup from "yup"

const FormWizard = () => {
  //meta title
  document.title = "Нууц үг солих | Diplom.mn"

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: Cookies.get("password") || "",
      password: "",
      password2: "",
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Хэтэрхий богино байна!")
        .max(50, "Хэтэрхий урт байна")
        .required("Заавал оруулна уу!"),
      password2: Yup.string()
        .min(8, "Хэтэрхий богино байна!")
        .max(50, "Хэтэрхий урт байна")
        .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна")
        .required("Заавал оруулна уу!"),
    }),
    onSubmit: async values => {
      const { password, password2 } = values
      try {
        const response = await changePassword({ password, password2 })
        if (response.status === 200) {
          toast.success(`Амжилттай`, {
            style: { fontSize: "18px" },
          })
        } else {
          toast.error(`Амилтгүй`, {
            style: { fontSize: "18px" },
          })
        }
      } catch (error) {
        toast.error(`Алдаа гарлаа`, {
          style: { fontSize: "18px" },
        })
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ТОХИРГОО" breadcrumbItem="Нууц үг солих" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Нууц үг цолих</h4>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="wizard clearfix"
                  >
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Шинэ нууц үг
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Нууц үг
                            баталгаажуулах
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <div>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Input
                                  type="password"
                                  name="password"
                                  onChange={validation.handleChange}
                                  onFocus={() => setactiveTab(1)}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password}
                                  className="form-control"
                                  id="basicpill-pancard-input5"
                                  placeholder="Шинэ нууц үгээ оруулна уу"
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Input
                                  type="password"
                                  name="password2"
                                  onChange={validation.handleChange}
                                  onFocus={() => setactiveTab(2)}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password2}
                                  className="form-control"
                                  id="basicpill-vatno-input6"
                                  placeholder="Баталгаажуулах нууц үгээ оруулна уу"
                                  invalid={
                                    validation.touched.password2 &&
                                    validation.errors.password2
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Солих
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard
