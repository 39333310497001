import axios from "axios"
import Cookies from "js-cookie"
import axiosRetry from "axios-retry"

const API_URL = `${process.env.REACT_APP_API_SERVICE_URL}/chaincode`
const API_ROOT = `${process.env.REACT_APP_API_SERVICE_URL}`
axiosRetry(axios, {
  retries: 3,
})
export async function addCreditHandler(values) {
  const data = values
  const token = Cookies.get("bearerToken")
  try {
    const response = await axios.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "MintCredit",
        args: [
          data.organization_credit_id,
          data.organization_id,
          data.credit_amount,
          data.title,
        ],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    return { message: "error" }
  }
}

export async function listCredits() {
  const token = Cookies.get("bearerToken")

  try {
    const myOrgData = await readMyOrg()
    const myOrgId = myOrgData.id
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ListCreditLog",
        args: [myOrgId, myOrgId],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function listOrganizations() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ListOrgs",
        args: [],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function addOrganization(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axios.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "CreateOrg",
        args: [
          data.organization_id,
          data.organization_name,
          data.description,
          data.institution_id,
          data.institution_name,
          data.organization_logo,
          data.initial_credit,
          data.credit_description,
          true,
        ],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function listAllDiplomas() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ListDiploma",
        args: ["", "", "desc"],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )

    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function callHemisDiplomaData({ id, tokenHemis }) {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/external-service/hemis/dsolution/diplom/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export async function addDiplomaBatch(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axios.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "CreateDiplomaBatch",
        args: [data],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function getPublicKey() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ReadMyOrg",
        args: [],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return { message: "error" }
  }
}

export async function getSignature(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/key/ecdsa/sign`,
      {
        message: data,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}
export async function listOrgDiplomas() {
  const token = Cookies.get("bearerToken")
  const myOrgData = await readMyOrg()
  const myOrgId = myOrgData.id
  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ListOrgDiploma",
        args: ["", "", "desc", myOrgId],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readDiplomaReport() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/admin-dashboard/report?channel=${process.env.REACT_APP_CHANNEL_UNI}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readMyOrgCredit() {
  const token = Cookies.get("bearerToken")
  const myOrgData = await readMyOrg()
  const myOrgId = myOrgData.id

  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ReadCredit",
        args: [myOrgId, myOrgId],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readMyOrg() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ReadMyOrg",
        args: [],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function changeCredentials(values) {
  const token = Cookies.get("bearerToken")
  const data = values
  try {
    const response = await axios.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "UpdateMyOrg",
        args: [values.id, data.organization_name, data.email, data.logoUrl],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function changePassword(data) {
  const token = Cookies.get("bearerToken")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_API}/service-wallet/identity/change-password`,
      data,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function uploadLogo(file) {
  const token = Cookies.get("bearerToken")
  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}
export async function hemisToken(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axios.post(
      `${API_ROOT}/admin/hemis-token`,
      {
        username: data.username,
        password: data.password,
        tokenHemis: data.tokenHemis,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    Cookies.set("hemisToken", response.data.token)
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}
