import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
const TopCitiesSelling = [
  {
    id: 1,
    location: "Completed",
    value: "1,456",
    progress: {
      value: "94",
      color: "primary",
    },
  },
  {
    id: 2,
    location: "Pending others",
    value: "1,123",
    progress: {
      value: "82",
      color: "success",
    },
  },
  {
    id: 3,
    location: "Rejected",
    value: "1,026",
    progress: {
      value: "70",
      color: "warning",
    },
  },
]
const TopCities = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Issued certificates</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>1,456</h3>
            <p>Completed</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {(TopCitiesSelling || []).map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">{item.location}</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <h5 className="mb-0">{item.value}</h5>
                    </td>
                    <td>
                      <Progress
                        value={item.progress.value}
                        color={item.progress.color}
                        className="bg-transparent progress-sm"
                        size="sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
