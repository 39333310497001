import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const DiplomaDetailsModal = props => {
  const { data, index, isOpen, toggle } = props

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Дипломын дэлгэрэнгүй</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table table-nowrap">
              <tbody>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">ID:</h6>
                  </td>
                  <td>{data[index].id}</td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Нэр:</h6>
                  </td>
                  <td>{data[index].claims.extras.hemisDiploma.FIRST_NAME}</td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Овог:</h6>
                  </td>
                  <td>{data[index].claims.extras.hemisDiploma.LAST_NAME}</td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Голч дүн:</h6>
                  </td>
                  <td>{data[index].claims.extras.hemisDiploma.TOTAL_GPA}</td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Дипломын дугаар:</h6>
                  </td>
                  <td>
                    {data[index].claims.extras.hemisDiploma.DEGREE_NUMBER}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Регистрийн дугаар:</h6>
                  </td>
                  <td>
                    {
                      data[index].claims.extras.hemisDiploma
                        .PRIMARY_IDENTIFIER_NUMBER
                    }
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Байгууллагын дугаар:</h6>
                  </td>
                  <td>
                    {data[index].claims.extras.hemisDiploma.INSTITUTION_ID}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Байгууллага:</h6>
                  </td>
                  <td>
                    {data[index].claims.extras.hemisDiploma.INSTITUTION_NAME}
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын түвшин:</h6>
                  </td>
                  <td>
                    {
                      data[index].claims.extras.hemisDiploma
                        .EDUCATION_LEVEL_NAME
                    }
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын салбарын код:</h6>
                  </td>
                  <td>
                    {
                      data[index].claims.extras.hemisDiploma
                        .EDUCATION_FIELD_CODE
                    }
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Боловсролын салбар:</h6>
                  </td>
                  <td>
                    {
                      data[index].claims.extras.hemisDiploma
                        .EDUCATION_FIELD_NAME
                    }
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td colSpan="">
                    <h6 className="m-0">Хичээлийн жил:</h6>
                  </td>
                  <td>
                    {data[index].claims.extras.hemisDiploma.CONFER_YEAR_NAME}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Хаах
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

DiplomaDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default DiplomaDetailsModal
