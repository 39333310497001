import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "reactstrap"
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap"
import { Link } from "react-router-dom"
import profile1 from "assets/images/profile-img.png"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "./WelcomeComp"
import TableContainerWithModal from "components/Common/TableContainerWithModal"
import { DateCell, TextCell } from "pages/CreditLog/creditLogCol"
import { IdCell } from "pages/AllDiplomaList/diplomaListCol"
import { StatusCell } from "pages/OrganizationList/organizationLogCol"
import { listCredits, listOrganizations } from "helpers/api_helper"
import TopCities from "./TopCities"
import TableContainer from "components/Common/TableContainer"

const SuperAdminDashboard = props => {
  const reports = [
    { title: "Total Uploaded", iconClass: "bx-copy-alt", description: "1,235" },
    {
      title: "Total Pending",
      iconClass: "bx-archive-in",
      description: "1,235",
    },
    {
      title: "Total Credits",
      iconClass: "bx-purchase-tag-alt",
      description: "1,235",
    },
  ]
  //meta title
  document.title = "Dashboard | Diplom.mn"

  const [organizations, setOrganizations] = useState(null)
  const [creditData, setCreditData] = useState(null)

  const fetchOrganizations = async () => {
    try {
      const r = await listOrganizations()
      const err = r
      if (err?.message != "error") {
        setOrganizations(r)
      }
    } catch (error) {
      return "error"
    }
  }
  const fetchCredits = async () => {
    try {
      const r = await listCredits()
      const err = r
      if (err?.message != "error") {
        setCreditData(r)
      }
    } catch (error) {
      return "error"
    }
  }
  useEffect(() => {
    fetchOrganizations()
    fetchCredits()
  }, [])
  const organizationColumns = useMemo(
    () => [
      {
        Header: "Transaction Date",
        accessor: "createTxTimestamp",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Organization Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Institution Id",
        accessor: "institutionId",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Institution Name",
        accessor: "institutionName",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Description",
        accessor: "desc",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Organization credit id",
        accessor: "orgCreditId",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Logo",
        accessor: "logoUrl",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <StatusCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )

  const creditColumns = useMemo(
    () => [
      {
        Header: "Transaction Date",
        accessor: "txTimestamp",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Organization Id",
        accessor: "orgId",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Credit Id",
        accessor: "creditId",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="4">
              {/* <WelcomeComp /> */}
              {reports.map((report, key) => (
                <Col key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}

              <TopCities />
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <div className="mb-4 h4 card-title">Organizations</div>
                  <TableContainer
                    columns={organizationColumns}
                    data={organizations ? organizations : []}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    customPageSize={5}
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="mb-4 h4 card-title">Credit Transactions</div>
                  <TableContainer
                    columns={creditColumns}
                    data={creditData ? creditData : []}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isPagination={true}
                    isShowingPageLength={true}
                    iscustomPageSizeOptions={false}
                    customPageSize={15}
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SuperAdminDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(SuperAdminDashboard)
