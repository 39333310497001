import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { TextCell, DateCell } from "./creditLogCol"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import toast from "react-hot-toast"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import TableContainer from "components/Common/TableContainer"
import { addCreditHandler, listCredits } from "helpers/api_helper"

const CreditLog = props => {
  const [apiData, setApiData] = useState(null)
  const fetchCredits = async () => {
    try {
      const r = await listCredits()
      const err = r
      if (err?.message != "error") {
        setApiData(r)
      }
    } catch (error) {
      return "error"
    }
  }

  useEffect(() => {
    fetchCredits()
  }, [])

  //meta title
  document.title = "Credit List | Diplom.mn"

  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Please Enter Your Name"),
    //   designation: Yup.string().required("Please Enter Your Designation"),
    //   tags: Yup.array().required("Please Enter Tag"),
    //   email: Yup.string()
    //     .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
    //     .required("Please Enter Your Email"),
    //   projects: Yup.string().required("Please Enter Your Project"),
    // }),
    onSubmit: async values => {
      const r = await addCreditHandler(values)
      if (r) {
        const err = r?.result
        if (!err?.length) {
          toast.success("Credit Added Successfully")
        } else {
          toast.error(err)
        }
      }
      // if (isEdit) {
      //   const updateUser = {
      //     id: contact.id,
      //     name: values.name,
      //     designation: values.designation,
      //     tags: values.tags,
      //     email: values.email,
      //     projects: values.projects,
      //   }
      //   // update user
      //   dispatch(onUpdateUser(updateUser))
      //   setIsEdit(false)
      //   validation.resetForm()
      // } else {
      //   const newUser = {
      //     id: Math.floor(Math.random() * (30 - 20)) + 20,
      //     name: values["name"],
      //     designation: values["designation"],
      //     email: values["email"],
      //     tags: values["tags"],
      //     projects: values["projects"],
      //   }
      //   // save new user
      //   dispatch(onAddNewUser(newUser))
      //   validation.resetForm()
      // }
      // toggle()
    },
  })

  // const selectContactsState = state => state.contacts
  // const ContactsProperties = createSelector(selectContactsState, Contacts => ({
  //   users: Contacts.users,
  //   loading: Contacts.loading,

  // }))

  // const { users, loading } = useSelector(ContactsProperties)

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState()

  const columns = useMemo(
    () => [
      {
        Header: "Transaction Date",
        accessor: "txTimestamp",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Organization Id",
        accessor: "orgId",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Credit Id",
        accessor: "creditId",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers())
  //     setIsEdit(false)
  //   }
  // }, [dispatch, users])

  // useEffect(() => {
  //   setContact(users)
  //   setIsEdit(false)
  // }, [users])

  // useEffect(() => {
  //   if (!isEmpty(users) && !!isEdit) {
  //     setContact(users)
  //     setIsEdit(false)
  //   }
  // }, [users])

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    })
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  // const onClickDelete = users => {
  //   setContact(users)
  //   setDeleteModal(true)
  // }

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItem="CREDIT LIST" />
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={apiData ? apiData : []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Organization Name</Label>
                        <Input
                          name="organization_credit_id"
                          placeholder="Insert orgaization credit id"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Organization Id</Label>
                        <Input
                          name="organization_id"
                          label="Organization Id"
                          placeholder="Insert organization id"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.designation &&
                            validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Credit Amount</Label>
                        <Input
                          name="credit_amount"
                          label="Credit Amount"
                          placeholder="Insert credit amount"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Title</Label>
                        <Input
                          name="title"
                          label="Title"
                          placeholder="Insert title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreditLog
