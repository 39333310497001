import PropTypes from "prop-types"
import React from "react"
import Cookies from "js-cookie"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, RouterProvider } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useNavigate } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images

import logo from "assets/images/logo3.png"
import loginHandler from "helpers/jwt-token-access/service_helper"
import bgImage from "assets/images/bg-auth-overlay.png"

const Login = props => {
  //meta title
  document.title = "Нэвтрэх | Diplom.mn"
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: Cookies.get("rememberUsername") || "",
      password: "",
      rememberMe: !!Cookies.get("rememberUsername"),
    },
    // validationSchema: Yup.object({
    //   email: Yup.string().required("Please Enter Your Email"),
    //   password: Yup.string().required("Please Enter Your Password"),
    // }),
    onSubmit: async values => {
      const { username, password, rememberMe } = values
      const auth = await loginHandler({ username, password })

      if (auth !== "error") {
        Cookies.set("authUser", JSON.stringify(auth)) // Always set this

        if (rememberMe) {
          Cookies.set("rememberUsername", username, { expires: 7 }) // Expires in 7 days
        } else {
          Cookies.remove("rememberUsername")
        }

        navigate("/")
      } else {
        // Handle login error
      }
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundColor: "#cfd5f6",
          backgroundPosition: "",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <img
                width={456}
                src={logo}
                style={{
                  width: "",
                }}
                className=""
              />
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-2">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Хэрэглэгчийн нэр</Label>
                        <Input
                          name="username"
                          id="username"
                          className="form-control"
                          placeholder="Хэрэглэгчийн нэрээ оруулна уу"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username}
                          // value={validation.values. || ""}
                          // invalid={
                          //   validation.touched.email && validation.errors.email
                          //     ? true
                          //     : false
                          // }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Нууц үг</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Нууц үгээ оруулна уу"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="rememberMe"
                          name="rememberMe"
                          checked={validation.values.rememberMe}
                          onChange={validation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Сануулах
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Нэвтрэх
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault()
                                socialResponse("facebook")
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li> */}
                      {/*<li className="list-inline-item">*/}
                      {/*  <TwitterLogin*/}
                      {/*    loginUrl={*/}
                      {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                      {/*    }*/}
                      {/*    onSuccess={this.twitterResponse}*/}
                      {/*    onFailure={this.onFailure}*/}
                      {/*    requestTokenUrl={*/}
                      {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                      {/*    }*/}
                      {/*    showIcon={false}*/}
                      {/*    tag={"div"}*/}
                      {/*  >*/}
                      {/*    <a*/}
                      {/*      href=""*/}
                      {/*      className="social-list-item bg-info text-white border-info"*/}
                      {/*    >*/}
                      {/*      <i className="mdi mdi-twitter"/>*/}
                      {/*    </a>*/}
                      {/*  </TwitterLogin>*/}
                      {/*</li>*/}
                      {/* <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault()
                                socialResponse("google")
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Нууц үгээ мартсан уу?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    
                    Signup now
                  </Link>{" "}
                </p> */}
                <p>© {new Date().getFullYear()} . DIPLOM.MN</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
