import Cookies from "js-cookie"
import React from "react"
import { Navigate, redirect } from "react-router-dom"

const Authmiddleware = props => {
  if (!Cookies.get("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  } else if (
    !props.isSuperAdmin &&
    props.isPrivileged &&
    !JSON.parse(Cookies.get("authUser")).superAdmin
  ) {
    return <React.Fragment>{props.children}</React.Fragment>
  } else if (
    props.isSuperAdmin &&
    props.isPrivileged &&
    JSON.parse(Cookies.get("authUser")).superAdmin
  ) {
    return <React.Fragment>{props.children}</React.Fragment>
  } else if (!props.isPrivileged)
    return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
