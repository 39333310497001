import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import { DateTime } from "luxon"

const IdCell = cell => {
  return (
    <Link
      to={
        cell.value
          ? `https://check.diplom.mn/check/${cell.value}?channel=university-prod`
          : "#"
      }
      target="_blank"
      className="text-body fw-bold"
    >
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const StatusCell = cell => {
  return (
    <Badge
      className={
        "font-size-11 badge-soft-" +
        (cell.value === "new"
          ? "success"
          : "danger" && cell.value === ""
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}

export { IdCell, StatusCell }

const TextCell = cell => {
  return cell.value ? cell.value : ""
}
const DateCell = cell => {
  return cell.value
    ? DateTime.fromSeconds(cell.value).toFormat("yyyy-MM-dd HH:mm:ss")
    : cell.value
}

const Img = cell => {
  return (
    <>
      {!cell.value ? (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {cell.data[0].name.charAt(0)}
          </span>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
        </div>
      )}
    </>
  )
}

export { TextCell, DateCell, Img }
