import React, { useState, useEffect } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import { useDropzone } from "react-dropzone"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { changeCredentials, readMyOrg, uploadLogo } from "helpers/api_helper"
import toast from "react-hot-toast" // Import toast and Toaster

const ChangeInformation = props => {
  //meta title
  document.title = "Мэдээлэл шинэчлэх | Diplom.mn"
  const [selectedFiles, setselectedFiles] = useState([])
  const [logoFile, setLogoFile] = useState(null)

  const handleAcceptedFiles = async files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setLogoFile(files[0])
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".jpg", ".jpeg", ".webp"] },
    onDrop: acceptedFiles => handleAcceptedFiles(acceptedFiles),
  })

  const [isOrgDataLoading, setIsOrgDataLoading] = useState(false)
  const [orgData, setOrgData] = useState()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: "",
      organization_name: "",
      email: "",
      logoUrl: "",
    },

    validationSchema: Yup.object({
      organization_name: Yup.string().required("Заавал оруулна уу!"),
      email: Yup.string().required("Заавал оруулна уу!"),
    }),
    onSubmit: async values => {
      let logoUrl = orgData.logoUrl
      if (logoFile) {
        const res = await uploadLogo(logoFile)
        if (res.status === 200) {
          logoUrl = res.data.fullUrl
        } else {
          toast.error(`Файл оруулахад алдаа гарлаа`, {
            style: { fontSize: "18px" },
          })
        }
      }
      try {
        const response = await changeCredentials({
          ...values,
          logoUrl: logoUrl,
        })
        if (response.status === 200) {
          toast.success(`Амжилттай`, {
            style: { fontSize: "18px" },
          })
          window.location.reload()
        } else {
          toast.error(`Амилтгүй`, {
            style: { fontSize: "18px" },
          })
        }
      } catch (error) {
        toast.error(`Алдаа гарлаа`, {
          style: { fontSize: "18px" },
        })
      }
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        setIsOrgDataLoading(true)
        const r = await readMyOrg()
        console.log("org data:")
        console.log(r)
        setOrgData(r)
        validation.setFieldValue("id", r.id)
        validation.setFieldValue("organization_name", r.name)
        validation.setFieldValue("email", r.email)
        validation.setFieldValue("logoUrl", r.logoUrl)
      } finally {
        setIsOrgDataLoading(false)
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ТОХИРГОО" breadcrumbItem="Мэдээлэл шинэчлэх" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Лого оруулах
                        </Label>
                        <div className="dropzone" {...getRootProps()}>
                          <input
                            type="file"
                            accept="image/*"
                            {...getInputProps()}
                          />
                          <div className="dz-message needsclick mt-2">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Энд дарж файлаа байршуулна уу.</h4>
                          </div>
                        </div>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles
                            ? selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col className="col-auto">
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })
                            : null}
                        </div>
                      </div>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Logo</Label>
                          {validation.values.logoUrl ? (
                            <img
                              style={{
                                maxHeight: "80px",
                                marginLeft: "20px",
                                width: "auto",
                              }}
                              src={validation.values.logoUrl}
                            />
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Байгууллагын нэр
                          </Label>
                          <Input
                            type="text"
                            name="organization_name"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Байгууллагын нэрээ оруулна уу"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.organization_name}
                            invalid={
                              validation.touched.organization_name &&
                              validation.errors.organization_name
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Имейл хаяг
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Имейл хаягаа оруулна уу"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Солих
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default ChangeInformation
